import React from 'react';
import Reveal from 'react-reveal';


const AppGetstarted =()=>{
    return(
        <section className="get_started_area">
                     <div className="container">
                <div className="row pb-20" id='spiffing'>
                    <div className="col-lg-6 d-flex align-items-center">
                        <Reveal bottom cascade>
                            <div className="get_content">
                                <h2 className="l_height50 mb_20 wow fadeInLeft" data-wow-delay="0.3s"><span className='acct'>Manage </span> your business' finances with us</h2>                              
                            
                            </div>
                        </Reveal>
                    </div>
                    <div className="col-lg-6 text-right">
                       <Reveal effect="fadeInRight" duration={2200}> 
                       <p className="righttext f_400 f_p mb_40 wow fadeInLeft" data-wow-delay="0.4s">Whether you have a small or large business. Managing finances is a big deal. We are dedicated to keeping them accurate and clean to help you focus on growing your business.</p>
                       </Reveal>
                    </div>
                </div>

                <div className="row">
                    <div className="col-lg-3 col-md-6 d-flex align-items-center">
                        <Reveal bottom cascade>
                            <div className="get_content">
                            <Reveal effect="fadeInRight" duration={2200}> <img src={require('../img/home2/accounting.png')} alt="" className='servimg'/></Reveal>
                            <a href="/Accountspayable"><h2 className="acc l_height50 mb_20 wow fadeInLeft" data-wow-delay="0.3s">Accounting</h2></a>
                            <p>It is organizing your financial data which involves a variety of tasks like tax compliance, budgeting forecasting etc. </p>
                            <h4 className="enterprise"><a href="/Accountspayable">Read More</a></h4>                                         
                            </div>
                        </Reveal>
                    </div>
                    <div className="col-lg-3 col-md-6 d-flex align-items-center">
                        <Reveal bottom cascade>
                            <div className="get_content">
                            <Reveal effect="fadeInRight" duration={2200}> <img src={require('../img/home2/network.png')} alt="" className='servimg'/></Reveal>
                            <a href="/Staffing"><h2 className="acc l_height50 mb_20 wow fadeInLeft" data-wow-delay="0.3s">Staffing</h2></a>
                            <p>The major goal of staffing is to find suitable candidates and fill the various roles within the company.</p>
                            <h4 className="enterprise"><a href="/Staffing">Read More</a></h4>                                    
                            </div>
                        </Reveal>
                    </div>
                    <div className="col-lg-3 col-md-6 d-flex align-items-center">
                        <Reveal bottom cascade>
                            <div className="get_content">
                            <Reveal effect="fadeInRight" duration={2200}> <img src={require('../img/home2/checklist.png')} alt="" className='servimg'/></Reveal>
                            <a href="/DataEntry"> <h2 className="acc l_height50 mb_20 wow fadeInLeft" data-wow-delay="0.3s">Data Entry</h2></a>
                            <p> Need to update records regularly? Data entry does that through computerised methods. </p>
                            <h4 className="enterprise"><a href="/DataEntry">Read More</a></h4>                                      
                            </div>
                        </Reveal>
                    </div>
                    <div className="col-lg-3 col-md-6 d-flex align-items-center">
                        <Reveal bottom cascade>
                            <div className="get_content">
                            <Reveal effect="fadeInRight" duration={2200}> <img src={require('../img/home2/social-media.png')} alt="" className='servimg'/></Reveal>
                            <a href="/DigitalMarketing"><h2 className="acc l_height50 mb_20 wow fadeInLeft" data-wow-delay="0.3s">Digital Marketing</h2></a>
                            <p> Promote your brand and connect with potential customers using the internet like email & social media marketing etc. </p>
                            <h4 className="enterprise"><a href="/DigitalMarketing">Read More</a></h4>                                       
                            </div>
                        </Reveal>
                    </div>
                </div>

            

            </div>
        </section>
    )
}
export default AppGetstarted;