import React from 'react';



const ErpAction =()=> {
    return(
        <section className="erp_action_area topmarg">
            <div className="container">
                <div className="row align-items-center">

                    <div className="col-lg-12 col-md-8 text-center conwidth mx-auto">
                        <div className="erp_content">
                            <h2>Trusted by <span className='over'>10,000+ Businesses</span> Worldwide</h2>                          
                            <a href="/Contact" className="chat_btn1 btn_hover mt-3">Get Started</a>
                     </div>
                    </div>
                </div>
            </div>
        </section>
    )
}
export default ErpAction;