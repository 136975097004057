import React from 'react';

import vid1 from '../img/home2/vid1.mp4';
import Reveal from 'react-reveal';


const chatClients =()=>{
    return(
        <div>

            <section className="chat_get_started_area conwidth mx-auto">
                <div className="container">
                <Reveal bottom cascade>
                <div className="chat_title text-center">
                    <h2 className="l_height50 mb_20 wow fadeInLeft" data-wow-delay="0.3s"><span>Specialized</span> to make your job easier</h2>
                    <p>With our several years of experience in this industry, we have helped 10,000+ businesses successfully. </p>                  
                </div>
                </Reveal>
                <video className='chatvideo' src={vid1}  autoPlay loop muted />


                </div>
            </section>
        </div>
    )
}
export default chatClients;