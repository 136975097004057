import React, {Component} from 'react';
import Reveal from 'react-reveal';

import { Link } from 'react-router-dom';

class Section1 extends Component {
    render(){
        return(
            <section class="chat_features_area" >                
                <div className="hero__item hero__height d-flex align-items-center">
               <div className="container">
                  <div className="row">

                     <div className="col-xl-6 col-md-6 col-lg-6 d-flex align-items-center">
                     <Reveal bottom cascade>
                        <div className="hero__content">
                           <h2 className="l_height50 mb_20 wow fadeInLeft" data-wow-delay="0.3s"><span>Handling </span>all your major business operations </h2>
                           <p>Our professionals allow firms to focus on what they love. We work hard to ensure that we handle your finances better, whether your business is big or small.</p>
                           <p>With us, you can hire staff, handle accounts and build your business online. You can rely on us without regrets. </p>                      
                           <Link to="/About-us" className="z-btn z-btn-border">ABOUT US</Link>
                        </div>
                     </Reveal>
                     </div>

                     <div className="col-xl-6 col-md-6 col-lg-6 text-center">
                           <div className="">
                              <img className="sliderimg" src={require('../../img/home2/feature1.png')} alt=""/>
                           </div>                          
                     </div>


                     
                  </div>
               </div>
            </div>



            </section>
        )
    }
}
export default Section1;