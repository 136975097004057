import React from 'react';
import profiselogo from '../../img/logo.png';



const FooterFour = () => {
    return (
        <>
        <section className='second footer'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-xxl-3 col-xl-3 col-md-6'>
                        <a href='/'>
                        <img src={profiselogo} className="footerlogo" />
                        </a>
                             <div className='cont'>
                         <p className='address'><span>Contact:</span><a href='tel:425-435-6184'>425-435-6184</a></p>
                <p className='address'><span>Email:</span><a href='mailto:info@theprofise.com'>info@theprofise.com</a></p>
                <form action="#" class="f_subscribe mailchimp" method="post">
                <input type="text" name="EMAIL" class="form-control memail" placeholder="Email" />
                <button class="btn btn-submit" type="submit"><i class="ti-arrow-right"></i></button>
                </form>
                         </div>
                       
                        </div>
                        <div className='col-xxl-3 col-xl-3 col-md-6' id='footercol'>
                        <h4>Company</h4>
                        <ul className='Solutions'>
                            <li><a href="/About-us">About Us</a></li>
                            <li><a href="/Whychooseus">Why Choose Us</a></li>
                            <li><a href="/On-boarding">On-Boarding</a></li>
                            <li><a href="/">Blog</a></li>
                        </ul>
                        </div>
                        <div className='col-xxl-3 col-xl-3 col-md-6' id='footercol'>
                        <h4>Services</h4>
                        <ul className='Solutions'>
                            <li><a href="/Accountspayable">Accounting</a></li>
                            <li><a href="/Staffing">Staffing</a></li>
                            <li><a href="/DataEntry">Data Entry</a></li>
                            <li><a href="/DigitalMarketing">Digital Marketing</a></li>
                        </ul>
                        </div>
                        <div className='col-xxl-3 col-xl-3 col-md-6' id='footercol'>
                        <h4>Industries</h4>
                        <ul className='Solutions'>
                            <li><a href="/Trucking">Trucking</a></li>
                            <li><a href="/Healthcare">Healthcare</a></li>
                            <li><a href="/Manufacturing">Manufacturing</a></li>
                            <li><a href="/Others">Others</a></li>
                        </ul>
                        </div>
                    </div>
                </div>
            </section>
    
                               <div className="copyright-area copyright-style-one no-border">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-lg-5 col-md-5 col-sm-12 col-12">
                                <div className="copyright-left">
                                    <ul className="ft-menu link-hover">
                                        <li><a href="/Privacypolicy">Privacy Policy</a></li>
                                        <li><a href="Termsconditions">Terms Of Use</a></li>
                                        <li><a href="/contact">Contact Us</a></li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-lg-7 col-md-7 col-sm-12 col-12">
                                <div className="copyright-right text-center text-md-right">
                                    <p className="copyright-text"> © Copyright 2023. All Rights Reserved by <a href="https://theprofise.com/">The Profise</a></p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                  </>
    )
}

export default FooterFour;
