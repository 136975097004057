import React, {Component} from 'react';
import Reveal from 'react-reveal';

import { Link } from 'react-router-dom';

class Section2 extends Component {
    render(){
        return(
            <section>                
               <div className="hero__item1 hero__height d-flex align-items-center">
               <div className='container'>
                     <div className='row'>

                        <div className="col-xl-12 col-lg-12 purplewidth align-items-center sli-top">
                        <div className='row'>

                        <div className="col-xl-6 col-md-6 col-lg-6 align-items-center">
                              <img className="sliderimg1" src={require('../../img/home2/bookkeeper.png')} alt=""/>
                        </div>

                           <div className="col-xl-6 col-md-6 col-lg-6  hero__content1 align-items-center">
                           <Reveal bottom cascade>
                                 <h2><span>Crack</span> your deals superfast and grow your business with us</h2>
                                 <ul className='sli-ul1 bot-padding'>
                                    <li>We are committed to being transparent with our customers.</li>
                                    <li>Never feel alone; we are here to help you with your business finances.</li>
                                    <li>Not every business is built the same, so our accountant professionals provide you with a financial plan specially created for your business.</li>
                                 </ul>
                           </Reveal>
                           </div>
                           
                           </div>
                        </div>

                     </div>

            </div>
               </div>

            </section>



        )
    }
}
export default Section2;