import React from 'react';

const ErpAction1 =()=> {
    return(
        <section className="erp_action_area2">
            <div className="container">
                <div className="row bord align-items-center">

                    <div className="col-lg-8 col-md-12 col-sm-12">
                        <div className="erp_content2">
                        <h2><span className='over2'> The Profise </span>takes all your business loads.</h2>
                        <h2>Rise above the competitors.</h2>
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-12 col-sm-12 text-center">
                    <a href="/Contact" className="chat_btn1 btn_hover mt-3">Get Started</a>
                    </div>

                </div>
            </div>
        </section>
    )
}
export default ErpAction1;